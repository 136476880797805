Container and General Styles
/* html, body {
    height: 100%;
    margin: 0;
  } */
  
  .container1 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
  }
  
  .recruiter-form {
    width: 100%;
    max-width: 1400px;
    padding: 40px;
    background-color:#e3e7f3;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Section Headers */
  .section h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .section p {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
  }
  
  /* Form Group and Row */
  .form-row {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .form-group input,
  .form-group select {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    background-color: #f7f7f7;
  }
  
  .form-group select {
    height: 50px;
  }
  
  .radio-group {
    display: flex; /* Use flexbox for horizontal alignment */
    gap: 1rem; /* Adjust the gap between the options */
    align-items: center; /* Align items vertically in the center */
  }
  
  .radio-group label {
    display: flex; /* Ensure that the label contents are aligned properly */
    align-items: center; /* Align items vertically in the center */
    gap: 0.5rem; /* Space between the radio button and label text */
  }
  
  .password-options .radio-group {
    align-items: center;
    margin-top: 10px;
  }
  
  .group-description {
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .group-description p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  /* Create Group Button */
  .create-group-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #f8a009;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
  }
  
  /* Form Actions */
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .cancel-button,
  .save-button {
    padding: 12px 30px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #333;
    border: none;
  }
  
  .save-button {
    background-color: #007bff;
    color: #fff;
    border: none;
  }
  /* register.css */

.error {
  color: red; /* Set text color to red */
  font-size: 0.875rem; /* Adjust font size if needed */
  margin-top: 0.25rem; /* Add space above the error message */
}

.container {
  /* Your container styles */
}

.back-button1 {
  /* Your back button styles */
  background-color: #007bff;
  color: #e3e7f3;
  border-radius: 5px;
}

.recruiter-form {
  /* Your form styles */
}

.section {
  /* Your section styles */
}

.form-row {
  /* Your form row styles */
}

.form-group {
  /* Your form group styles */
}

.radio-group {
  /* Your radio group styles */
}

.password-options {
  /* Your password options styles */
}

.create-group-button {
  /* Your create group button styles */
}

.group-description {
  /* Your group description styles */
}

.actions {
  /* Your form actions styles */
}

/* .cancel-button */
.head{
  border-bottom: 1px solid black;
}