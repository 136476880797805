body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f4f8;
  }
  
  .container2 {
    display: flex;
    height: 100vh;
  }
  
  .left-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(to bottom right, #e0eafc, #cfdef3); */
    background-image: url('../../assets/images/users/ddd.png');
  }
  
  .text-content {
    text-align: center;
    padding: 20px;
  }
  
  .text-content h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .text-content p {
    font-size: 16px;
    color: #666;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .right-section {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
  }
  
  .login-box {
    width: 100%;
    max-width: 400px;
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-box h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .login-box p {
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .input-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .input-group label {
    display: block;
    text-align: left;
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
  }
  
  .show-password {
    position: absolute;
    right: 10px;
    /* top: 40px; */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .login-button:hover {
    background-color: #333;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .left-section {
      display: none;
    }
  
    .right-section {
      width: 100%;
      padding: 20px;
    }
  
    .login-box {
      padding: 30px;
    }
  
    .login-box h2 {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .login-box {
      padding: 20px;
    }
  
    .login-box h2 {
      font-size: 18px;
    }
  }
  