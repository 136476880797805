.dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* height: 100vh !important; */
}

.item-card {
  width: 250px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  word-wrap: break-word; /* Ensures words break to the next line if they exceed the width */
  overflow-wrap: break-word; /* For better browser support */
  overflow: hidden; /* Hides any overflowing content */

}

.icon-folder img,
.icon-file img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000 !important;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  /* overflow-y: auto; */
  z-index: 10000000000 !important;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
}

/* .modal-content-two-column{
  margin-top: 25%;
  overflow-y: auto;
} */

.custom-pdf-viewer {
  padding: 20px; /* Add padding around the viewer */
  border: 1px solid #ddd; /* Add a border around the viewer */
  background-color: #f8f9fa; /* Change the background color */
  /* width: 55%; */
  display: flex;
  flex-direction: row;
}

.custom-wrapper {
  max-height: 700px; /* Set a maximum height for the viewer */
  overflow-y: auto; /* Add vertical scrolling */
}

.custom-canvas {
  border: 1px solid #ccc; /* Add border to the PDF canvas */
  /* margin: 0 auto; Center the canvas */
  width: 70%;
}

.custom-navbar {
  background-color: #007bff; /* Change the navbar background color */
  padding: 10px; /* Add padding */
  display: none;
}

.custom-nav-button {
  background-color: #fff; /* Change the navigation button background */
  color: #007bff; /* Change button text color */
  border: 1px solid #007bff; /* Add border */
  border-radius: 5px; /* Add rounded corners */
  margin: 0 5px; /* Add some spacing */
  display: none;
}

.close-btn {
  background-color: #007bff;
  color: #f8f9fa;
  display: flex;
  /* justify-items: end; */
  margin-left: auto;
}

.search-div {
  display: flex;
  justify-content: space-between;
}

.hover-button {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border: 2px solid #dfdfdf;
  background-color: transparent;
  color: #007bff;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.3s ease; /* Smooth transition */
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}

.icon {
  display: inline-block;
  transition: transform 0.3s ease;
  margin-right: 0; /* No margin initially */
}

.text {
  display: none; /* Hide text initially */
  transition: opacity 0.3s ease;
  margin-left: 5px;
}

.hover-button:hover .icon {
  transform: translateX(-10px); /* Move icon slightly to the left */
}

.hover-button:hover .text {
  display: inline-block;
  width: 80px;
  opacity: 1;
}

.hover-button1 {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border: 2px solid #dfdfdf;
  background-color: #f0801e;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease; /* Smooth transition */
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}

.icon1 {
  display: inline-block;
  transition: transform 0.3s ease;
  margin-right: 0; /* No margin initially */
  background-color: #f0801e;
}

.text1 {
  display: none; /* Hide text initially */
  transition: opacity 0.3s ease;
  margin-left: 5px;
}

.hover-button1:hover .icon1 {
  transform: translateX(-10px); /* Move icon slightly to the left */
}

.hover-button1:hover .text1 {
  display: inline-block;
  width: 100px;
  background-color: #f0801e;
  opacity: 1;
}

/* comment section */
.comment-box {
  background-color: #f8f9fa;
  padding: 20px; /* Add padding around the viewer */
  border: 1px solid #ddd; /* Add a border around the viewer */
  /* Change the background color */
  width: 50%;
}
.submit-btn-comment {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: "18px";
  font-family: "Poppins";
  color: #ffffff;
  background: #0747a6;
  text-transform: "none";
  width: "100%";
  border-radius: "5px";
  padding: "10px";
  cursor: "no-drop";
  height: 50px;
  /* "&:hover": {
    color: "#FFFFFF";
    background: "#0747A6";
    cursor: "no-drop";
  }, */
}
.submit-btn-comment:hover {
  color: #ffffff;
  background: #0747a6;
  cursor: no-drop;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: "18px";
  color: #ffffff;
  background: #0747a6;
  text-transform: "none";
  width: "100%";
  border-radius: "5px";
  padding: "10px";
  height: 50px;
  /* "&:hover": {
    color: "#FFFFFF";
    background: "#0747A6";
  }, */
}
.submit-btn:hover {
  color: #ffffff;
  background: #0747a6;
  cursor: pointer;
}
/* .input-group input  */
.comment-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.comment-date {
  color: #908c8c;
  font-size: 10px;
}

.custom-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
}

.custom-textarea:focus {
  border-color: #66afe9; /* Light blue on focus */
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -20vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

p {
  margin-top: 10px;
  /* font-size: 1.2rem; */
  color: #333;
}

