/* Modal Styles */
.Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .modal-header {
    margin-bottom: 20px;
  }
  
  .success-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .modal-body {
    margin-top: 10px;
  }
  
  .group-details {
    text-align: left;
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
  }
  
  .detail {
    margin-bottom: 5px;
  }
  